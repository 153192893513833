import { BlogList } from '@/BlogList';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import { graphql } from 'gatsby';

const Blogs: Page = ({ data }) => {
    const heroImage = data.allFile.edges[0].node.childImageSharp.gatsbyImageData;
    const blogs = data.allMdx.nodes;
    console.log(blogs);
    return (
        <main>
            <DefaultLayout
                title="Media"
                heroImage={heroImage}
                heroText="Browse through our series of podcasts and articles featuring some of our Bloom La Vie Health doctors!"
                // heroText={data.mdx.frontmatter.heroText}
            >
                <Seo
                    title="Media | Bloom La Vie Health"
                    description="Browse through our series of podcasts and articles featuring some of the doctors from Bloom La Vie Health!"
                />
                <BlogList blogs={blogs} />
            </DefaultLayout>
        </main>
    );
};

export default Blogs;

export const query = graphql`
    query MdxBlogPost {
        allMdx(
            filter: { fields: { sourceInstanceName: { eq: "media" } } }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            nodes {
                frontmatter {
                    title
                    slug
                    date(formatString: "MMM DD, YYYY")
                    images {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE)
                        }
                    }
                }
                body
                excerpt(truncate: true)
            }
        }
        allFile(filter: { relativePath: { eq: "girl-on-phone.jpeg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
        }
    }
`;
